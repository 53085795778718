import axios from 'axios';
import { MappedProfileKeys } from './constants';
import { Profile } from '../context/ProfileContext';

export const fetchProfile = async (
  setLoading: (loading: boolean) => void,
  setError: (error: string | null) => void,
  setProfile: (profile: Profile) => void
) => {
  try {
    setLoading(true);
    const accountInfoResponse = await axios.post('/api/get-account');

    if (!accountInfoResponse?.data?.success) {
      setError('Failed to get account info');
      return;
    }

    const profileData = accountInfoResponse?.data?.data;

    if (!profileData) {
      setError('No profile data found');
      return;
    }

    const { firstName, lastName, email, picture } = profileData;

    let imageUrl = '';

    if (typeof picture === 'string' && picture?.trim() !== '') {
      try {
        const proxyImage = await axios.get(
          `/api/proxy-image?url=${encodeURIComponent(picture)}`
        );
        imageUrl = proxyImage?.config?.url || '';
      } catch (proxyError) {
        imageUrl = '';
      }
    }
    setProfile({
      firstName,
      lastName,
      email,
      picture: imageUrl,
    });

    localStorage.setItem(
      MappedProfileKeys.PROFILE_KEY,
      JSON.stringify({
        firstName,
        lastName,
        email,
        imageUrl,
      })
    );
    // store in local storage
    setError(null);
  } catch (error) {
    setError('Failed to fetch profile');
  } finally {
    setLoading(false);
  }
};
