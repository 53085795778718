import clsx from 'clsx';
import Image from 'components/common/Image';
import LineLogo from '@public/icons/line.svg';
import MessengerLogo from '@public/icons/messenger-chat.svg';

import {
  ContactButton,
  CustomerPortalButton,
  HamburgerButton,
  Header,
  PhoneButton,
} from '@alphafounders/ui';
import useTranslation from 'next-translate/useTranslation';
import { Settings } from 'interfaces/cms/layout';
import Link from 'components/common/Link';
import { baseUrl } from 'lib/constants';
import Head from 'next/head';
import LanguageButton from './LanguageButton';
import GoogleProfileImage from 'components/StaticContent/Profile/ProfileImage';
import { isEnableGoogleOneTapLogin, useProfile } from 'context/ProfileContext';
import useMediaQuery from 'hooks/useMediaQuery';

const rabbitLogo = process.env.NEXT_PUBLIC_RABBIT_LOGO_IMAGE;

const locales = [
  { text: 'ไทย', code: 'th' },
  { text: 'EN', code: 'en' },
];

export interface LayoutHeaderProps extends Settings {
  locale: string;
  slug?: string;
  isMultipleLang?: boolean;
  navigationIsOpen?: boolean;
  toggleNavigation?: () => void;
}

const CUSTOMER_PORTAL_URL = process.env.NEXT_PUBLIC_CUSTOMER_PORTAL;

function LayoutHeaderV2({
  headerText,
  openingTimes,
  phone,
  lineUrl,
  messengerUrl,
  slug,
  locale,
  navigationIsOpen,
  toggleNavigation,
  isMultipleLang,
  structuredData,
}: Readonly<LayoutHeaderProps>) {
  const { t } = useTranslation('common');
  const isExpat = slug === 'expat-insurance' || slug === 'oversea-insurance';
  const { profile, isLoggedIn } = useProfile();

  const [isMobile] = useMediaQuery();

  const renderContactButtons = () => {
    const buttons = [];

    if (!isExpat) {
      buttons.push({
        href: lineUrl as string,
        imagePath: `${baseUrl}/icons/line.svg`,
        altText: 'Line',
        ariaLabel: 'LINE Add Friend',
        icon: <LineLogo />,
      });

      buttons.push({
        href: messengerUrl as string,
        imagePath: `${baseUrl}/icons/messenger.svg`,
        altText: 'Facebook',
        ariaLabel: 'MSN Messenger',
        icon: <MessengerLogo />,
      });
    }

    return buttons.map((props) => (
      <ContactButton
        className="bg-primaryColor border-primaryColor"
        key={props.href}
        {...props}
      />
    ));
  };

  const renderLocaleButtons = () => {
    if (!isMultipleLang) return null;
    return locales.map((props) => (
      <LanguageButton
        dataTestId="lang-button"
        selected={props.code === locale}
        key={props.code}
        slug={slug}
        {...props}
      />
    ));
  };

  const phoneText = () => {
    return phone;
  };

  const getProfileUrl = () =>
    locale === 'th' ? '/profile' : `/${locale}/profile`;

  const getProfileImage = () => {
    if (!profile.picture) return undefined;
    return (
      <GoogleProfileImage
        src={profile.picture}
        alt={profile.firstName || 'User Profile'}
        priority
        width={isMobile ? 30 : 25}
        height={isMobile ? 30 : 25}
      />
    );
  };

  const getProfileButton = () => {
    if (!isLoggedIn) return undefined;
    return (
      <Link href={getProfileUrl()}>
        <CustomerPortalButton
          text={t('customerPortal_text')}
          icon={getProfileImage()}
        />
      </Link>
    );
  };

  return (
    <>
      <Head>
        {Boolean(structuredData) && (
          <script
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
          />
        )}
      </Head>
      <div
        data-testid="header-v2"
        className="px-4 lg:px-0 bg-white sticky top-0 z-20 h-[3.75rem] items-center flex w-full shadow-sm"
      >
        <Header
          menuButton={
            <HamburgerButton
              className="lg:collapse stroke-primaryColor"
              isOpen={navigationIsOpen}
              onClick={toggleNavigation}
            />
          }
          icon={
            <Link href="/" locale={locale}>
              <div
                className={clsx(
                  'relative',
                  'w-[91px] sm:w-[135px] h-[15px] sm:h-[20px] lg:w-[180px] lg:h-[28px]'
                )}
              >
                {rabbitLogo && (
                  <Image
                    src={rabbitLogo}
                    alt="Rabbit Care Logo"
                    fill
                    priority={true}
                    placeholder="empty"
                    quality={100}
                  />
                )}
              </div>
            </Link>
          }
          label={headerText || t('header_text')}
          phoneButton={
            phone &&
            openingTimes && (
              <PhoneButton
                number={phoneText() as string}
                subText={openingTimes}
                className="bg-primaryColor border-primaryColor md:fill-primaryColor md:text-primaryColor sm:hover:border-primaryColor"
              />
            )
          }
          contactButtons={renderContactButtons()}
          customerPortalButton={
            isEnableGoogleOneTapLogin ? (
              getProfileButton()
            ) : (
              <CustomerPortalButton
                href={`${CUSTOMER_PORTAL_URL}/${locale}`}
                text={t('customerPortal_text')}
              />
            )
          }
          localeButtons={renderLocaleButtons()}
        />
      </div>
    </>
  );
}

export default LayoutHeaderV2;
