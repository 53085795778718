import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { GoogleOneTapLogin } from '@alphafounders/googleonetap';
import { MappedProfileKeys } from 'lib/constants';
import { fetchProfile } from 'lib/profile';

export interface Profile {
  firstName: string;
  lastName: string;
  email: string;
  picture: string;
}

interface ProfileContextProps {
  profile: Profile;
  loading: boolean;
  error: string | null;
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
}

const ProfileContext = createContext<ProfileContextProps | undefined>(
  undefined
);

export const isEnableGoogleOneTapLogin =
  process.env.NEXT_PUBLIC_ENABLE_GOOGLE_LOGIN === 'true';

export const ProfileProvider = ({ children }: { children: ReactNode }) => {
  const token = Cookies.get(MappedProfileKeys.RC_GOOGLE_KEY);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState<string | null>();
  const [profile, setProfile] = useState<any>({});
  const [profileLoading, setProfileLoading] = useState(true);

  const expiredDate = new Date();
  expiredDate.setTime(expiredDate.getTime() + 1 * 60 * 60 * 1000); // 1 hour in milliseconds

  useEffect(() => {
    return setIsLoggedIn(!!token && !error);
  }, [token, error]);

  useEffect(() => {
    if (token) {
      const data =
        token && localStorage.getItem(MappedProfileKeys.PROFILE_KEY)
          ? JSON.parse(
              localStorage.getItem(MappedProfileKeys.PROFILE_KEY) || ''
            )
          : fetchProfile(setProfileLoading, setError, setProfile);
      if (data) setProfileLoading(false);
      return setProfile(data);
    } else {
      setIsLoggedIn(false);
      return;
    }
  }, []);

  const onSuccess = async (token: any) => {
    setIsLoggedIn(true);
    Cookies.set(MappedProfileKeys.RC_GOOGLE_KEY, token, {
      expires: expiredDate,
      path: '/',
      secure: true,
      sameSite: 'lax',
    });
    try {
      // Wait for the account creation API to complete
      const response = await axios.post('/api/create-account');

      if (!response?.data?.success) {
        setError('Error in account validation');
        setIsLoggedIn(false);
        return;
      }
      setProfileLoading(true);

      try {
        await fetchProfile(setProfileLoading, setError, setProfile);
      } catch (fetchError) {
        setError('Error fetching profile');
        setProfileLoading(false);
      }
    } catch (error) {
      setError('Error in account validation');
      setIsLoggedIn(false);
    }
  };

  return (
    <>
      {isEnableGoogleOneTapLogin && !isLoggedIn && (
        <GoogleOneTapLogin
          clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? ''}
          onSuccess={(token: string) => {
            onSuccess(token);
          }}
          onError={() => console.log('this  is error')}
          isLoggedIn={isLoggedIn}
        />
      )}
      <ProfileContext.Provider
        value={{
          profile: profile || null,
          loading: profileLoading,
          error: error || null,
          isLoggedIn,
          setIsLoggedIn,
        }}
      >
        {children}
      </ProfileContext.Provider>
    </>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }

  return context;
};

export const MockProfileProvider = ({ children }: { children: ReactNode }) => {
  const mockProfile = {
    email: '',
    firstName: 'John',
    lastName: 'Doe',
    picture: '',
  };
  const loading = false;

  return (
    <ProfileContext.Provider
      value={{
        profile: mockProfile,
        loading,
        error: null,
        isLoggedIn: true,
        setIsLoggedIn: () => {},
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
