export const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || '';

export enum WebsiteLocales {
  th = 'th',
  en = 'en',
}

export const MappedProfileKeys = {
  RC_GOOGLE_KEY: 'rc-google-id-token',
  PROFILE_KEY: 'profile',
};
